// src/components/VocabularyApp.js

import React from 'react';
import { Container, Tabs, Tab } from '@mui/material';
import VocabularyTable from './VocabularyTable';
import TranslationForm from './TranslationForm';
import ImageTranslationForm from './ImageTranslationForm';
import VoiceTranslationForm from './VoiceTranslationForm';
import SimplifiedVocabularyTable from './SimplifiedVocabularyTable';

function VocabularyApp() {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <h1>My Vocabulary</h1>      
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="Navigation Tabs"
        >
          <Tab label="Vocabulary" />
          <Tab label="Simplified Vocabulary" />
          <Tab label="Translate Text" />
          <Tab label="Translate Image" />
          <Tab label="Translate Voice" />
        </Tabs>
      </div>
      {tabValue === 0 && <VocabularyTable />}
      {tabValue === 1 && <SimplifiedVocabularyTable />}
      {tabValue === 2 && <TranslationForm />}
      {tabValue === 3 && <ImageTranslationForm />}
      {tabValue === 4 && <VoiceTranslationForm />}
      <div style={{opacity: 0.1, color:"grey"}}>Version 2025.2.20.1</div>      
    </Container>
  );
}

export default VocabularyApp;
